<template>
  <div class="recipes-edit">
    <div class="my-page">
      <div class="common-info">
        <van-form @submit="onSubmit">
          <van-field
            readonly
            required
            v-model="form.RecipesDate"
            name="食谱日期"
            label="食谱日期"
            placeholder="请选择食谱日期"
            :rules="[{ required: true, message: '请选择食谱日期' }]"
          />
          <van-field
            readonly
            clickable
            name="picker"
            v-model="editMode"
            label="编辑形式"
            placeholder="请选择编辑形式"
            @click="isShowEditMode = true"
          />

          <!-- 食谱信息 -->
          <div class="recipes-infos" v-if="editMode === '图文形式食谱'">
            <div
              class="info-item"
              v-for="(item, index) in imageTextList"
              :key="item.ID"
            >
              <div class="title">
                <span>第{{ index + 1 }}条食谱信息</span>
                <span style="color: red" @click="removeRecipes(index)"
                  ><van-icon name="delete-o" size="14px" />删除</span
                >
              </div>
              <div class="form">
                <van-field
                  required
                  readonly
                  clickable
                  name="picker"
                  v-model="item.MealsTypeName"
                  label="用餐类型"
                  placeholder="请选择用餐类型"
                  @click="mealTypeClick(item, index)"
                  :rules="[{ required: true, message: '请选择用餐类型' }]"
                />
                <van-field
                  required
                  v-model="item.RecipesContent"
                  autosize
                  type="textarea"
                  name="食谱内容"
                  label="食谱内容"
                  placeholder="请输入食谱内容"
                  :rules="[{ required: true, message: '请输入食谱内容' }]"
                />
                <van-field name="uploader" label="食谱照片">
                  <template #input>
                    <van-uploader
                      v-model="item.AccessoryPostList"
                      :deletable="true"
                      :preview-full-image="true"
                      accept="image/*"
                      :before-read="beforeRead"
                      @click-upload="clickUpload(index)"
                    />
                  </template>
                </van-field>
              </div>
            </div>
            <div
              class="btn"
              @click="addRecipes"
              v-if="imageTextList && imageTextList.length < 3"
            >
              <div>
                <van-icon name="add" /><span style="margin-left: 5px"
                  >新增</span
                >
              </div>
            </div>
          </div>
          <div v-else>
            <van-field name="uploader" label="食谱照片">
              <template #input>
                <van-uploader
                  v-model="AccessoryPostList"
                  :deletable="true"
                  :preview-full-image="true"
                  accept="image/*"
                  :before-read="beforeRead"
                />
              </template>
            </van-field>
          </div>
        </van-form>
      </div>
    </div>

    <div class="btns" @click="save">
      <van-button>保存</van-button>
    </div>

    <!-- 编辑形式 -->
    <van-popup v-model="isShowEditMode" position="bottom">
      <van-picker
        show-toolbar
        :columns="editModes"
        @confirm="confirmEditMode"
        @cancel="isShowEditMode = false"
      />
    </van-popup>

    <!-- 用餐类型 -->
    <van-popup v-model="isShowMealType0" position="bottom">
      <van-picker
        show-toolbar
        :columns="mealTypes"
        @confirm="confirmMealType"
        @cancel="isShowMealType0 = false"
      />
    </van-popup>
    <van-popup v-model="isShowMealType1" position="bottom">
      <van-picker
        show-toolbar
        :columns="mealTypes"
        @confirm="confirmMealType"
        @cancel="isShowMealType1 = false"
      />
    </van-popup>
    <van-popup v-model="isShowMealType2" position="bottom">
      <van-picker
        show-toolbar
        :columns="mealTypes"
        @confirm="confirmMealType"
        @cancel="isShowMealType2 = false"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  Button,
  Form,
  Field,
  Popup,
  Picker,
  Uploader,
  Icon,
  Toast
} from "vant";
export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Uploader.name]: Uploader,
    [Icon.name]: Icon,
    [Toast.name]: Toast
  },
  data() {
    return {
      recipesDate: null,
      recipesDeatil: null,
      isShowEditMode: false,
      isShowMealType0: false,
      isShowMealType1: false,
      isShowMealType2: false,
      currentTypeIndex: -1,
      form: {
        RecipesDate: "",
        RecipesType: "",
        ID: ""
      },
      AccessoryPostList: [],
      imageTextList: [],
      editModes: [
        { text: "图文形式食谱", value: "1" },
        { text: "仅图片形式", value: "2" }
      ],
      editMode: "图文形式食谱",
      mealTypes: [
        { text: "早加餐", value: "1" },
        { text: "午餐", value: "2" },
        { text: "下午茶", value: "3" }
      ],
      mealType: "",
      uploader: []
    };
  },
  created() {
    this.getRecipesData();
  },
  methods: {
    // 当天日期
    getRecipesData() {
      this.recipesDate = this.$route.query.date;
      this.form.RecipesDate = this.recipesDate;
      this.getRecipesDetail(this.recipesDate);
    },
    // 获取当天的膳食详情
    async getRecipesDetail(beginDate) {
      const res = await this.$axios.get("/api/Recipes/Get", {
        beginDate
      });
      this.recipesDeatil = res.data;
      this.form.ID = this.recipesDeatil.ID;
      this.editMode =
        this.recipesDeatil.RecipesType === 1 ? "图文形式食谱" : "仅图片形式";
      this.AccessoryPostList = this.recipesDeatil.accessoryList || [];
      this.imageTextList = this.recipesDeatil.imageTextList.map(item => {
        // item.isShowPop = false;
        return item;
      });
    },
    // 添加食谱
    addRecipes() {
      this.imageTextList.push({
        AccessoryList: null,
        AccessoryPostList: [],
        ID: null,
        MalesType: 1,
        MealsTypeName: "",
        RecipesContent: "",
        RecipesID: ""
      });
    },
    // 移除当前食谱
    removeRecipes(index) {
      this.imageTextList.splice(index, 1);
    },
    // 监听上传区域的点击
    clickUpload(index) {
      this.currentTypeIndex = index;
    },
    // 文件上传
    beforeRead(file) {
      Toast.loading({
        message: "加载中...",
        duration: 0
      });
      let formData = new FormData();
      formData.append("LastFile", file);
      formData.append("FileName", file.name);
      this.$axios.upload("/api/File/UploadFile", formData).then(res => {
        console.log(this.AccessoryPostList);
        Toast.clear();
        if (res.code === 200) {
          if (this.editMode === "仅图片形式") {
            this.AccessoryPostList.push({
              name: file.name,
              osskey: res.data.osskey,
              uploadUrl: res.data.uploadUrl,
              url: res.data.url
            });
          } else {
            this.imageTextList[this.currentTypeIndex].AccessoryPostList.push({
              name: file.name,
              osskey: res.data.osskey,
              uploadUrl: res.data.uploadUrl,
              url: res.data.url
            });
          }
          return true;
        } else {
          this.$toast.fail(res.msg || "操作失败");
          return false;
        }
      });
    },
    // 编辑形式确认
    confirmEditMode(event) {
      console.log(event);
      this.editMode = event.text;
      this.isShowEditMode = false;
    },
    mealTypeClick(item, index) {
      console.log(item);
      this[`isShowMealType${index}`] = true;
      this.currentTypeIndex = index;
    },
    // 用餐类型确认
    confirmMealType(event) {
      console.log(event);
      this.imageTextList[this.currentTypeIndex].MealsTypeName = event.text;
      this.imageTextList[this.currentTypeIndex].MealsType = event.value;
      this[`isShowMealType${this.currentTypeIndex}`] = false;
    },
    onSubmit(values) {
      this.$axios.post("/Recipes/Edit", this.form).then(res => {
        if (res.code === 200) {
          Toast({
            message: "操作成功"
          });
          this.$router.push("/RecipesViewAll");
        } else {
          Toast({
            message: res.msg
          });
        }
      });
    },
    // 保存
    save() {
      this.form.RecipesType = this.editMode === "图文形式食谱" ? 1 : 2;
      this.form.imageTextList = this.imageTextList;
      this.form.AccessoryPostList = this.AccessoryPostList;
      console.log(this.form);
      this.onSubmit();
    }
  }
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
